.navbar-wrapper {
  background: rgb(255, 224, 0);
  padding: 24px;
  border: 1px solid rgb(0, 51, 102);
  width: fit-content;
  margin: 12px auto;
  position: relative;
  z-index: 3;
}
.navbar {
  padding-inline-start: initial;
  list-style-type: none;
  display: flex;
  justify-content: center;
}
.navbar li {
  margin: 0 12px;
}
.navbar li a {
  text-decoration: none;
}

.bolletjesfeest {
  background: rgb(0, 51, 102);
  height: 2px;
  width: 100%;
}
.bolletjesfeest .bolletje {
  background: black;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  top: -7px;
  transition: left 0.42s;
}

@media screen and (max-width: 500px) {
  .navbar {
    flex-direction: column;
  }
}
