body {
  background: #e6d131;
  font-family: 'Patua One';
  color: rgb(0, 51, 102);
}
body a {
  color: rgb(0, 51, 102);
}

.main-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#blokjes-moeten-wegvluchten-hiervoor {
  padding: 24px;
  width: 0;
  height: 0;
  animation-name: scale-in;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes scale-in {
  from {
    width: 0;
    height: 0;
  }
  99% {
    width: 0;
    height: 0;
  }
  to {
    width: auto;
    height: auto;
  }
}
